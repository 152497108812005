import axios from '@/common/axios'
import qs from 'qs'

export function addMaterialProductStock (data) {
  return axios({
    method: 'post',
    url: '/storage/materialProductStock/add',
    data: qs.stringify(data)
  })
}

export function deleteMaterialProductStock (id) {
  return axios({
    method: 'delete',
    url: '/storage/materialProductStock/delete/' + id
  })
}

export function updateMaterialProductStock (data) {
  return axios({
    method: 'put',
    url: '/storage/materialProductStock/update',
    data: qs.stringify(data)
  })
}

export function selectMaterialProductStockInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/materialProductStock/info/' + id
  })
}

export function selectMaterialProductStockList (query) {
  return axios({
    method: 'get',
    url: '/storage/materialProductStock/list',
    params: query
  })
}

export function selectMaterialProductStockByNo (productNo) {
  return axios({
    method: 'get',
    url: '/storage/materialProductStock/selectByNo/' + productNo
  })
}
