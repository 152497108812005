<template>
  <div id="schedule">
    <el-dialog
      :title="scheduleFormTitle"
      width="1500px"
      :visible.sync="scheduleDialogVisible"
      :close-on-click-modal="false"
      @close="scheduleDialogClose"
    >
      <el-form
        ref="scheduleFormRef"
        :model="scheduleForm"
        :rules="scheduleFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="requisitionNo">
              <el-input
                v-model="scheduleForm.requisitionNo"
                placeholder="请输入编号"
                clearable
                :disabled="scheduleFormTitle !== '新增采购计划表'
                  && scheduleFormTitle !== '修改采购计划表'
                  && scheduleFormTitle !== '采购计划表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品编号" prop="productId">
              <el-select
                v-model="scheduleForm.productId"
                placeholder="请选择产品"
                clearable
                filterable
                :disabled="scheduleFormTitle !== '新增采购计划表'
                  && scheduleFormTitle !== '修改采购计划表'
                  && scheduleFormTitle !== '采购计划表详情'"
                @change="materialProductStockChange"
              >
                <el-option
                  v-for="item in materialProductStockList"
                  :key="item.id"
                  :label="item.productNo"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="scheduleForm.productName"
                placeholder="请输入产品名称"
                clearable
                :disabled="scheduleFormTitle !== '新增采购计划表'
                  && scheduleFormTitle !== '修改采购计划表'
                  && scheduleFormTitle !== '采购计划表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="scheduleForm.spec"
                placeholder="请输入型号规格"
                clearable
                :disabled="scheduleFormTitle !== '新增采购计划表'
                  && scheduleFormTitle !== '修改采购计划表'
                  && scheduleFormTitle !== '采购计划表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="scheduleForm.quantity"
                placeholder="请输入数量"
                :disabled="scheduleFormTitle !== '新增采购计划表'
                  && scheduleFormTitle !== '修改采购计划表'
                  && scheduleFormTitle !== '采购计划表详情'"
              >
                <i slot="suffix" class="el-input__icon el-icon-success" @click="calQuantity" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="scheduleFormTitle === '新增采购计划表'
              || scheduleFormTitle === '修改采购计划表'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column field="materialNo" title="采购物品编号">
              <template v-slot="{row, column}">
                <el-select
                  v-model="row[column.property]"
                  placeholder="请选择物品"
                  clearable
                  filterable
                  @change="materialPurchaseStockChange(row)"
                >
                  <el-option
                    v-for="item in materialPurchaseStockList"
                    :key="item.id"
                    :label="item.materialNo"
                    :value="item.materialNo"
                  >
                    <span style="float: left">{{ item.materialNo }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.productName }}</span>
                  </el-option>
                </el-select>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="materialName"
              title="名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="spec"
              title="规格型号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="factory"
              title="供应商"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="realStock"
              title="库存"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="unitPrice"
              title="单价"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="quantity"
              title="计划数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column field="estimatedPrice" title="总金额">
              <template #default="{ row }">
                <span>{{ countAmount(row) }}</span>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="deliveryDate"
              title="计划日期"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="scheduleFormTitle === '新增采购计划表'
                || scheduleFormTitle === '修改采购计划表'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="审核结果" prop="confirmorResult">
              <el-radio-group
                v-model="scheduleForm.confirmorResult"
                :disabled="scheduleFormTitle !== '审核采购计划表'
                  && scheduleFormTitle !== '采购计划表详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="scheduleForm.approvalResult"
                :disabled="scheduleFormTitle !== '批准采购计划表'
                  && scheduleFormTitle !== '采购计划表详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <div slot="footer">
        <el-button @click="scheduleDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="scheduleFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.requisitionNo" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['PURCHASE_SCHEDULE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <!-- <el-table
      :data="schedulePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    > -->
    <el-table
      :data="schedulePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="requisitionNo" label="编号" />
      <el-table-column prop="applicant" label="申请人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applicationDate">{{ scope.row.applicationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="confirmor" label="审核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmationDate">{{ scope.row.confirmationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.confirmorResult === 1">通过</span>
          <span v-if="scope.row.confirmorResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['PURCHASE_SCHEDULE_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['PURCHASE_SCHEDULE_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <!-- <el-button
            v-if="checkPermission(['PURCHASE_SCHEDULE_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['PURCHASE_SCHEDULE_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['PURCHASE_SCHEDULE_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConfirm(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            v-if="checkPermission(['PURCHASE_SCHEDULE_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button> -->
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="schedulePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSchedule,
  deleteSchedule,
  updateSchedule,
  selectScheduleInfo,
  selectScheduleList,
  reviewSchedule,
  approvalSchedule
} from '@/api/purchase/schedule'
import { selectMaterialProductStockInfo, selectMaterialProductStockList } from '@/api/storage/materialProductStock'
import { selectMaterialPurchaseStockList } from '@/api/storage/materialPurchaseStock'
import moment from 'moment'
import XEUtils from 'xe-utils'

export default {
  data () {
    return {
      scheduleDialogVisible: false,
      scheduleFormTitle: '',
      scheduleForm: {
        id: '',
        requisitionNo: '',
        confirmorResult: '',
        approvalResult: '',
        status: '',
        type: 2,
        statusFlag: 1,
        productId: '',
        productNo: '',
        productName: '',
        spec: '',
        quantity: '',
        requisitionDetailJson: '',
        taskId: ''
      },
      scheduleFormRules: {
        requisitionNo: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}]
      },
      schedulePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        requisitionNo: '',
        type: 2
      },
      detailList: [],
      materialProductStockList: [],
      materialPurchaseStockList: []
    }
  },
  created () {
    selectScheduleList(this.searchForm).then(res => {
      this.schedulePage = res
    })
    selectMaterialProductStockList({ type: 1 }).then(res => {
      this.materialProductStockList = res.list
    })
    selectMaterialPurchaseStockList({ type: 1 }).then(res => {
      this.materialPurchaseStockList = res.list
    })
  },
  methods: {
    scheduleDialogClose () {
      this.$refs.scheduleFormRef.resetFields()
      this.detailList = []
    },
    scheduleFormSubmit () {
      if (this.scheduleFormTitle === '采购计划表详情') {
        this.scheduleDialogVisible = false
        return
      }
      this.$refs.scheduleFormRef.validate(async valid => {
        if (valid) {
          if (this.scheduleFormTitle === '新增采购计划表') {
            this.scheduleForm.id = ''
            this.scheduleForm.status = 1
            this.scheduleForm.requisitionDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addSchedule(this.scheduleForm)
          } else if (this.scheduleFormTitle === '修改采购计划表') {
            this.scheduleForm.requisitionDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateSchedule(this.scheduleForm)
          } else if (this.scheduleFormTitle === '审核采购计划表') {
            this.scheduleForm.status = 2
            await reviewSchedule(this.scheduleForm)
          } else if (this.scheduleFormTitle === '批准采购计划表') {
            this.scheduleForm.status = 3
            await approvalSchedule(this.scheduleForm)
          }
          this.schedulePage = await selectScheduleList(this.searchForm)
          this.scheduleDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.scheduleFormTitle = '新增采购计划表'
      this.scheduleDialogVisible = true
      this.scheduleForm.requisitionNo = 'CG' + moment().format('YYYYMM') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('审核删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSchedule(row.id)
        if (this.schedulePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.schedulePage = await selectScheduleList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.scheduleFormTitle = '修改采购计划表'
      this.scheduleDialogVisible = true
      this.selectScheduleInfoById(row.id)
    },
    handleConfirm (index, row) {
      this.scheduleFormTitle = '审核采购计划表'
      this.scheduleDialogVisible = true
      this.selectScheduleInfoById(row.id)
    },
    handleApproval (index, row) {
      this.scheduleFormTitle = '批准采购计划表'
      this.scheduleDialogVisible = true
      this.selectScheduleInfoById(row.id)
    },
    handleInfo (index, row) {
      this.scheduleFormTitle = '采购计划表详情'
      this.scheduleDialogVisible = true
      this.selectScheduleInfoById(row.id)
    },
    selectScheduleInfoById (id) {
      selectScheduleInfo(id).then(res => {
        this.scheduleForm.id = res.id
        this.scheduleForm.requisitionNo = res.requisitionNo
        this.scheduleForm.confirmorResult = res.confirmorResult
        this.scheduleForm.approvalResult = res.approvalResult
        this.scheduleForm.status = res.status
        this.scheduleForm.type = res.type
        this.scheduleForm.statusFlag = res.statusFlag
        this.scheduleForm.productId = res.productId
        this.scheduleForm.productNo = res.productNo
        this.scheduleForm.productName = res.productName
        this.scheduleForm.spec = res.spec
        this.scheduleForm.quantity = res.quantity
        this.scheduleForm.taskId = res.taskId
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectScheduleList(this.searchForm).then(res => {
        this.schedulePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectScheduleList(this.searchForm).then(res => {
        this.schedulePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectScheduleList(this.searchForm).then(res => {
        this.schedulePage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['PURCHASE_SCHEDULE_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['PURCHASE_SCHEDULE_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    materialProductStockChange (value) {
      this.scheduleForm.quantity = ''
      if (value) {
        selectMaterialProductStockInfo(value).then(res => {
          this.scheduleForm.productNo = res.productNo
          this.scheduleForm.productName = res.productName
          this.scheduleForm.spec = res.spec
          this.detailList = []
          for (let item of res.detailList) {
            this.detailList.push({
              materialNo: item.materialNo,
              materialName: item.productName,
              spec: item.spec,
              factory: item.manufactor,
              realStock: item.realStock,
              quantity: item.quantity,
              unitPrice: item.unitPrice
            })
          }
        })
      } else {
        this.scheduleForm.productNo = ''
        this.scheduleForm.productName = ''
        this.scheduleForm.spec = ''
        this.detailList = []
      }
    },
    materialPurchaseStockChange (row) {
      if (row.materialNo) {
        let materialPurchaseStock = this.materialPurchaseStockList.find(item => item.materialNo === row.materialNo)
        row.materialName = materialPurchaseStock.productName
        row.spec = materialPurchaseStock.spec
        row.factory = materialPurchaseStock.manufactor
        row.realStock = materialPurchaseStock.realStock
        row.unitPrice = materialPurchaseStock.unitPrice
      } else {
        row.materialName = ''
        row.spec = ''
        row.factory = ''
        row.realStock = ''
        row.unitPrice = ''
      }
    },
    calQuantity () {
      if (this.scheduleForm.quantity) {
        this.$refs.xTable.getTableData().tableData.map(item => {
          item.quantity *= this.scheduleForm.quantity
        })
      }
    },
    countAmount (row) {
      row.estimatedPrice = XEUtils.multiply(row.quantity, row.unitPrice)
      return row.estimatedPrice
    }
  }
}
</script>

<style>
</style>
